<template>
  <div class="mint-wrapper">
    <div class="fadeIn" v-if="account">
      <div v-if="this.myReferral == ''">
        <h5 class="text-center" v-if="!list">
          Join Niftyz. Buy now and be the first on the platform!
        </h5>
        <p>You are minting with:</p>
        <h3>
          {{ account }}
        </h3>
      </div>

      <input class="mint-amount d-none" type="number" v-model="amount" />
      <p v-if="this.myReferral == ''" class="small">
        NFT<span v-if="amount > 1 && price > 0">s</span> paying
        {{ total }} ETH<br />
      </p>
      <div
        class="btn mint-btn m-top-1"
        v-if="!isMinting && this.myReferral == ''"
        @click="mint()"
      >
        Mint Now
      </div>
      <div
        class="d-flex flex-column justify-content-between"
        v-if="this.myReferral != ''"
      >
        <h3 class="">
          Congratulations! <br />
          You are now the owner of a Premium Niftyz account!
        </h3>
        <h4>
          This grants you access to niftyz platform and private discord! Go to
          niftyz.io to register and start minting business NFTs!
        </h4>
        <h4>
          Follow us on Twitter for updates.
          <a href="https://twitter.com/Niftyz_io" target="_blank">
            <img class="pb-1 ms-1" src="../assets/social/twitter.png" alt=""
          /></a>
        </h4>
        <h5>Share your referral link with your friends and earn:</h5>
        <!-- <a :href="`#/mint/${this.myReferral}`">Referral</a> -->
        <div>
          <h6 class="mb-0">Referral:</h6>
          <p>{{ `${this.domain}#/mint/${this.myReferral}` }}</p>
        </div>
        <div class="style-link">
          <a href="https://platform.niftyz.io/#/">Mint your Badge now</a>
        </div>
        <div v-if="vault > 0">
          <hr />
          Compliments, you can withdraw:
          {{ vault }} ETH<br /><br />
          <div class="btn" v-if="!isMinting" @click="withdraw()">
            Withdraw Now
          </div>
        </div>
      </div>
      <p class="m-top-1" v-if="isMinting && !pending">
        Please confirm the transaction in Metamask
      </p>
      <p class="m-top-1" v-if="isMinting && pending">
        Your transaction was submitted, waiting for confirmation at:
      </p>
      <a
        v-if="explorerUrl !== undefined"
        :href="explorerUrl + pending"
        target="_blank"
        >{{ pending }}</a
      >
      <p v-if="explorerUrl === undefined">{{ pending }}</p>
    </div>
    <div v-if="!account">
      <div v-if="list">
        You're minting in list <b>{{ list.toUpperCase() }}</b>
      </div>
      <div class="btn mint-btn" @click="connect()">Connect Wallet</div>
    </div>
  </div>
</template>

<script>
import abi from "@/abi/abi.json";
import WalletConnectProvider from "@walletconnect/web3-provider";
import axios from "axios";
import Web3 from "web3";
import Web3Modal from "web3modal";

export default {
  name: "MintButtonPhysical",
  props: ["list"],
  data() {
    return {
      ABI: abi,
      contract: process.env.VUE_APP_CONTRACT_ADDRESS,
      network: process.env.VUE_APP_NETWORK,
      domain: process.env.VUE_APP_DOMAIN,
      api: process.env.VUE_APP_APIURL,
      method: "mint",
      price: "0.2",
      infuraId: "de09a7825efa417686d0b08abe19679f",
      explorerUrl: "https://etherscan.io/tx/",
      account: "",
      balance: 0,
      vault: 0,
      total: 1,
      amount: 1,
      myReferral: "",
      physicalToken: "0",
      isMinting: false,
      web3: "",
      pending: "",
      proof: [],
      listId: 1,
      networks: {
        ethereum: 1,
        rinkeby: 4,
        goerli: 5,
        polygon: 137,
        mumbai: 80001,
        ganache: 5777,
      },
    };
  },
  watch: {
    amount() {
      const app = this;
      if (app.amount > 5) {
        app.amount = 5;
      }
      if (app.amount <= 0) {
        app.amount = 1;
      }
      app.total = (app.price * app.amount).toFixed(3);
    },
  },
  methods: {
    async connect() {
      const app = this;
      let providerOptions = {};
      if (app.infuraId !== undefined) {
        console.log("Using infuraid:", app.infuraId);
        providerOptions = {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId: app.infuraId,
            },
          },
        };
      }
      // Instantiating Web3Modal
      const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions: providerOptions,
      });
      const provider = await web3Modal.connect();
      app.web3 = await new Web3(provider);
      // Checking if networkId matches
      const netId = await app.web3.eth.net.getId();
      if (parseInt(netId) !== app.networks[app.network]) {
        alert("Switch to " + app.network + " network!");
      } else {
        const accounts = await app.web3.eth.getAccounts();
        if (accounts.length > 0) {
          app.account = accounts[0];
          const nftContract = new app.web3.eth.Contract(app.ABI, app.contract);
          let price = await nftContract.methods.public_price().call();
          app.price = await app.web3.utils.fromWei(price, "ether");
          this.total = this.price;
          console.log("il prezzo è", app.total);
          app.balance = await nftContract.methods.balanceOf(app.account).call();
          console.log(app.balance);
          if (parseInt(app.balance) > 0) {
            const tokens = await nftContract.methods
              .tokensOfOwner(app.account)
              .call();
            app.myReferral = Buffer.from(tokens[0]).toString("base64");
            const wei = await nftContract.methods.vault(app.account).call();
            app.vault = app.web3.utils.fromWei(wei, "ether");
            console.log(app.myReferral);
          } else if (app.list !== undefined && app.list.length > 0) {
            console.log("Searching if in list:", app.list);
            const proof = await axios.get(
              app.api + "/proof/" + app.list + "/" + app.account
            );
            console.log("PROOF RESPONSE:", proof.data);
            if (!proof.data.error) {
              console.log("LIST DETAILS:", proof.data.details);
              app.proof = proof.data.proof;
              app.listId = proof.data.details.listId;
              app.price = proof.data.details.price.toString();
              app.total = (app.price * app.amount).toFixed(3);
              console.log("NOW MINTING WITH:", app.price, app.list);
            } else {
              alert(proof.data.message);
              app.account = "";
            }
          }
        }
      }
    },
    async mint() {
      const app = this;
      if (!app.isMinting) {
        app.isMinting = true;
        try {
          const msg = "address verification request";
          const signature = await app.web3.eth.personal.sign(msg, app.account);

          const nftContract = new app.web3.eth.Contract(app.ABI, app.contract);
          // console.log("nftContract", nftContract);
          const wei = app.web3.utils.toWei(app.total.toString(), "ether");
          // console.log("physicalToken", this.physicalToken);
          const estimated = await nftContract.methods
            .mintNFT(app.listId, app.proof, "")
            .estimateGas({
              from: app.account,
              value: wei.toString(),
            });
          const gasLimit = parseInt(estimated * 1.2).toString();
          const mintRes = await nftContract.methods
            .mintNFT(app.listId, app.proof, "")
            .send({
              from: app.account,
              value: wei.toString(),
              gas: gasLimit,
            })
            .on("transactionHash", (pending) => {
              app.pending = pending;
            });

          console.log(mintRes);
          await axios.post(app.api + "/create-user", {
            tokenId: mintRes.events.Transfer.returnValues.tokenId,
            mintTx: mintRes.transactionHash,
            address: app.account,
          });

          await axios.post(app.api + "/physical/consume", {
            value: app.physicalToken,
            address: app.account,
            message: msg,
            signature,
          });
          app.isMinting = false;
          app.pending = "";
          alert(app.method + " run successfully!");
          app.connect();
        } catch (e) {
          alert(e.message);
          app.isMinting = false;
        }
      }
    },
    async withdraw() {
      const app = this;
      if (!app.isMinting) {
        app.isMinting = true;
        try {
          const nftContract = new app.web3.eth.Contract(app.ABI, app.contract);
          const estimated = await nftContract.methods
            .withdrawFunds()
            .estimateGas({
              from: app.account,
            });
          const gasLimit = parseInt(estimated * 1.2).toString();
          await nftContract.methods
            .withdrawFunds()
            .send({
              from: app.account,
              gas: gasLimit,
            })
            .on("transactionHash", (pending) => {
              app.pending = pending;
            });
          app.isMinting = false;
          app.pending = "";
          alert("Funds withdrew successfully!");
          app.connect();
        } catch (e) {
          alert(e.message);
          app.isMinting = false;
        }
      }
    },
  },
  async mounted() {
    if (this.$route.params["physicalToken"] !== undefined) {
      // this.physicalToken = Buffer.from(
      //   this.$route.params["physical"],
      //   "base64"
      // ).toString();
      // this.physicalToken = JSON.parse(this.physicalToken);
      this.physicalToken = this.$route.params["physicalToken"];

      // const physicalTokenExistsRes = await axios.get(
      //   app.api + `/physical/exists/${this.physicalToken.progressive}`
      // )

      // if (!physicalTokenExistsRes.data.error) {
      //   if (physicalTokenExistsRes.data.exists) {

      //   }
      // }
    }
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: inline-block;

  line-height: 1.5;
  width: 200px;
  color: white;
  border: none;
  padding: 0.4rem;
  background: transparent linear-gradient(208deg, #6600cc 0%, #0099ff 100%) 0%
    0% no-repeat padding-box;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover {
  transition: all 0.45s;
  color: white;
  @keyframes bob {
    0% {
      background: transparent linear-gradient(208deg, #6600cc 0%, #0099ff 90%)
        0% 0% no-repeat padding-box;
    }
    20% {
      background: transparent linear-gradient(208deg, #6600cc 5%, #0099ff 100%)
        0% 0% no-repeat padding-box;
    }
    40% {
      background: transparent linear-gradient(208deg, #6600cc 10%, #0099ff 110%)
        0% 0% no-repeat padding-box;
    }
    60% {
      background: transparent linear-gradient(208deg, #6600cc 15%, #0099ff 120%)
        0% 0% no-repeat padding-box;
    }
    80% {
      background: transparent linear-gradient(208deg, #6600cc 20%, #0099ff 130%)
        0% 0% no-repeat padding-box;
    }
    100% {
      background: transparent linear-gradient(208deg, #6600cc 25%, #0099ff 140%)
        0% 0% no-repeat padding-box;
    }
  }
  animation: bob 0.5s ease-in-out alternate;
  background: transparent linear-gradient(208deg, #6600cc 30%, #0099ff 150%) 0%
    0% no-repeat padding-box;
}
.mint-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mint-wrapper .fadeIn {
  padding: 0px;
  margin-top: 2rem;
}
.mint-wrapper h3 {
  color: white;
  font-size: 1.5rem;
}
.mint-wrapper p {
  color: #c6c4c4;
}
.fadeIn {
  -webkit-animation: fadeIn 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fadeIn 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
input {
  font-size: 1.5rem;
  margin: 5px 0;
}
input:focus {
  outline: rgba(0, 0, 0, 0.5) solid 2px;
  -moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.8);
}
.m-top-1 {
  margin-top: 3rem;
}
.mint-wrapper {
  text-align: center;
  height: 100%;
  width: 100%;
}
p {
  margin: 0;
}
p.small {
  font-size: 0.9rem;
  font-style: italic;
  color: #7d7d7d;
}
@media screen and (max-width: 767px) {
  .btn {
    width: 170px;
  }
  .mint-wrapper {
    h3 {
      font-size: 0.9rem;
      margin: 0.5rem auto;
    }
  }
  input {
    font-size: 1.2rem;
  }
}
</style>
