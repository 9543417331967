<template>
  <div class="patners">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h4>Our Partners and Investors</h4>
        </div>
      </div>
      <!-- <div class="row align-items-center mt-5 mb-5">
        <div
          :class="isMobile ? 'mb-3' : null"
          class="col-6 col-md-4 col-lg-2 text-center"
        >
          <img class="w-75" src="../../assets/partners/foundry.svg" alt="" />
        </div>
        <div
          :class="isMobile ? 'mb-3' : null"
          class="col-6 col-md-4 col-lg-2 text-center"
        >
          <img class="w-75" src="../../assets/partners/rocket.svg" alt="" />
        </div>
        <div
          :class="isMobile ? 'mb-3' : null"
          class="col-6 col-md-4 col-lg-2 text-center"
        >
          <img class="w-75" src="../../assets/partners/valley.svg" alt="" />
        </div>
        <div
          :class="isMobile ? 'mb-3' : null"
          class="col-6 col-md-4 col-lg-2 text-center"
        >
          <img class="w-75" src="../../assets/partners/brinc.svg" alt="" />
        </div>
        <div
          :class="isMobile ? 'mb-3' : null"
          class="col-6 col-md-4 col-lg-2 text-center"
        >
          <img class="w-75" src="../../assets/partners/growth.svg" alt="" />
        </div>
        <div
          :class="isMobile ? 'mb-3' : null"
          class="col-6 col-md-4 col-lg-2 text-center"
        >
          <img class="w-75" src="../../assets/partners/logo_white.svg" alt="" />
        </div>
      </div> -->
    
    </div>
    <div class="section-img">
      <img src="../../assets/partners/Animoca.svg" alt="">
      <img src="../../assets/partners/brinc.svg" alt="">
      <img src="../../assets/partners/foundry.svg" alt="">
      <img src="../../assets/partners/LaunchpadLuna_white.svg" alt="">
      <img src="../../assets/partners/logo_white.svg" alt="">
      <img src="../../assets/partners/valley.svg" alt="">
      <img src="../../assets/partners/logo.svg" alt="">
      <img src="../../assets/partners/rocket.svg" alt="">
      <img src="../../assets/partners/growth.svg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "Parners",
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    const app = this;
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
};
</script>

<style></style>
