<template>
  <header>
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-12 col-md-6">
          <div class="d-flex align-items-center left-part">
            <a href="/">
              <div class="d-flex align-items-center">
                <img class="logo" src="../assets/logo.svg" alt="" />
              </div>
            </a>
            <div class="navigation">
              <a class="pe-2" href="#/about">Partners Programme</a>
              <a href="/#/#mint">Mint Now</a>
              <a v-if="isMobile && isMember" class="btn-platform" href="https://platform.niftyz.io/#/">Log into our Platform</a>
              <a v-if="isMobile && !isMember" class="btn-platform" href="/#/#mint">Buy your Niftyz Badge</a>

            </div>
          </div>
        </div>
        <div v-if="!isMobile" class="col-4 col-md-6 social-mobile">
          <div class="d-flex justify-content-end right-part">
            <ul class="d-flex m-0">
              <li>
                <a href="https://twitter.com/niftyz_io" target="blank"
                  ><img src="../assets/social/twitter.png" alt=""
                /></a>
              </li>
              <li>
                <a
                  href=" https://www.linkedin.com/company/niftyz/"
                  target="_blank"
                  ><img src="../assets/social/linkedin.png" alt=""
                /></a>
              </li>
              <li>
                <a href="https://discord.gg/ZcCxqjwaWZ" target="_blank"
                  ><img src="../assets/social/discord.png" alt=""
                /></a>
              </li>
      
            </ul>
            <a v-if="isMember" class="btn-platform" href="https://platform.niftyz.io/#/">Log into our Platform</a>
              <a v-if="!isMember" class="btn-platform" href="/#/#mint">Buy your Niftyz Badge</a>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </header>
</template>

<script>
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import abi from "@/abi/abi.json";

export default {
  name: "Header",
  data(){
    return{
      isMobile:false,
      ABI: abi,
      contract: process.env.VUE_APP_CONTRACT_ADDRESS,
      network: process.env.VUE_APP_NETWORK,
      domain: process.env.VUE_APP_DOMAIN,
      api: process.env.VUE_APP_APIURL,
      infuraId: "de09a7825efa417686d0b08abe19679f",
      isMember:false,
      networks: {
        ethereum: 1,
        rinkeby: 4,
        goerli: 5,
        polygon: 137,
        mumbai: 80001,
        ganache: 5777,
      },
    }
  },
    async mounted() {
    const app = this;
    await app.connect();
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
  methods:{
    async connect() {
      const app = this;
      let providerOptions = {};
      if (app.infuraId !== undefined) {
        console.log("Using infuraid:", app.infuraId);
        providerOptions = {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId: app.infuraId,
            },
          },
        };
      }
      // Instantiating Web3Modal
      const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions: providerOptions,
      });
      const provider = await web3Modal.connect();
      app.web3 = await new Web3(provider);
      // Checking if networkId matches
      /* ______ */
      const netId = await app.web3.eth.net.getId();
      if (parseInt(netId) !== app.networks[app.network]) {
        alert("Switch to " + app.network + " network!");
      }else{
        await app.fetchMember();
      }
      /* ______ */
    },
    async fetchMember(){
      const app = this;
      try {
        const accounts = await app.web3.eth.getAccounts();
        if (accounts.length > 0) {
          const account = accounts[0];
          const nftContract = new app.web3.eth.Contract(abi, process.env.VUE_APP_CONTRACT_ADDRESS);
          const balance = await nftContract.methods.balanceOf(account).call();
          if (parseInt(balance) > 0) {
            app.isMember = true
          }else{
            console.log('address is not member');
          }
        }
      } catch (err) {
        console.log(err.message??err);
      }
      console.log(this.isMember);
    }
  }

};
</script>

<style scoped lang="scss"></style>
