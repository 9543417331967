<template>
  <div class="faqs container pt-5">
    <div class="row">
      <div
        :class="isMobile ? 'text-center' : null"
        class="col-12 col-md-5 col-lg-4"
      >
        <h3>Frequently Asked Questions</h3>
        <p>
          Learn more about Niftyz ecosystem and our mission to bridge the gap
          between Web2 and Web3 through the real power of NFTs.
        </p>
      </div>
      <div
        :class="isMobile ? 'mt-1 p-4' : 'mt-5 pt-5'"
        class="col-12 col-md-7 col-lg-8"
      >
        <div class="faq" v-for="(faq, index) in faqs" :key="index">
          <div
            @click="faqs[index].opened = !faqs[index].opened"
            class="question"
          >
            <h4>
              {{ faq.question }}
            </h4>
            <i
              :class="faq.opened ? 'fa-chevron-up ' : 'fa-angle-down'"
              class="fa-solid"
            ></i>
          </div>
          <p v-html="faq.answer" class="answer" v-show="faq.opened"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Faqs",
  data() {
    return {
      faqs: [
        {
          question: "What is Niftyz?",
          answer:
            "Niftyz is a platform that enables businesses of all sizes and sectors to easily monetize their know-how, reports, documents and other digital and physical assets as NFTs or Non-fungible tokens. NFTs are not just for artists and gamers and you don’t need a ‘cute’ picture to get started! Niftyz spotted a gap in the market and wants to add value to businesses and their end clients by enabling them to create new revenue streams through this powerful technology.",
        },
        {
          question: "What is the Input Economy?",
          answer:
            "The Niftyz universe has an input-driven economy where registered users can turn their growth assets into liquid and tradable NFTs to fuel their development process; increase members’ loyalty; and create additional revenue streams. Users of the Niftyz marketplace can either buy, sell, or trade this information and contribute to the businesses’ growth and development. As opposed to Web2, Web3 aims to monetise the ‘Input’ (the work that goes into the creation of IP) rather than the ‘Output’ (the end product) and with Niftyz this is now possible. This is what we call the Input economy! Inputs can finally become a source of revenue, rather than being a passive asset.",
        },
        {
          question:
            "How is Niftyz different from other NFT marketplaces and platforms?",
          answer:
            "When most people think of NFTs they think of digital art online that has provided artists a totally new way to monetise their work. But the applications do not stop there. Niftyz has expanded the utility of NFTs to monetise business growth by locking valuable content and only granting access to either paying, or desirable users. Our platform is so versatile that it can cater for all types of businesses needs, verticals, stages of growth and more! We will keep releasing new templates as we come across new use cases. <br> Your only limitation is the extent of your own imagination!  ",
        },
        {
          question: "What are Niftyz Badges / Business identity?",
          answer:
            "Niftyz badges provide registered users with a personalised QR code that is linked to their Twitter account. Each badge is colour-coded, which instantly identifies users as investors, start-ups, advisors or service providers. Each Niftyz badge contains the company name, industry, location and the Niftyz logo (the Niftyz logo is only visible for Free accounts). Users can easily share their Niftyz badge on their website, business cards, Twitter, LinkedIn, email signatures and more. Badges can also be attached to their Avatars in the Metaverse when they attend events on platforms such as Decentraland, The Sandbox and many more to come! ",
        },
        {
          question: "What is a ‘Pass token’?",
          answer:
            "Simply put, it’s the NFT that unlocks all the assets related to your project, company, campaign and all your hard work! You can mint the NFT directly through the Niftyz platform! Upload your content, select attributes, targeting and number of ‘Pass tokens’ that you want to give to your audience. Finally lock it, mint it and share it!",
        },
        {
          question: "How do I mint a ‘Pass token’?",
          answer:
            "<ol style='padding-left:1rem'> <li> Firstly, create an account through the Niftyz platform. You can choose between a Basic (free) or Pro (premium - one off fee) account. </li> <li> Secondly, pick a template and add your assets. </li> <li>Then select a blockchain to mint your NFT. You will pay the minting fees, depending on your account type, in the same crypto currency as your selected blockchain (e.g. ETH for Ethereum or MATIC for Polygon).</li> <li>Finally, once the NFT has been minted, the cryptocurrency will be withdrawn from your wallet and the NFT will automatically be listed on Niftyz and on Open Sea. </li></ol>",
        },
        {
          question: "How do I purchase a Niftyz NFT / Pass Token?",
          answer:
            "Once minted, Pass Tokens will appear directly on the Niftyz marketplace and on Open Sea. All you have to do is to link your Metamask account to the selected platform, load it with the crypto currency listed for the NFT and buy it. Once purchased, the items, documents or assets locked behind the NFT Pass Token will be revealed and the NFT can be stored in your Metamask wallet.",
        },
        {
          question: "When will Niftyz launch?",
          answer:
            "You will be able to purchase the first limited number of Niftyz Pro accounts at a reduced price now. The minting platform will be ready in Q3 2022. In the meantime, please join our community channels to stay up to date. <br>  <br> Join the Discord Channel: <a style='text-decoration:underline' href='https://discord.gg/ZcCxqjwaWZ' target='_blank'> https://discord.gg/ZcCxqjwaWZ </a> <br> Join the Twitter community: <a style='text-decoration:underline' href='https://twitter.com/niftyz_io' target='_blank'> https://twitter.com/niftyz_io </a> <br> Those purchasing their account during the Private Sales will have beta access to the platform and will be able to start minting as soon as the platform goes live.",
        },
        {
          question: "What is the difference between basic and pro accounts?",
          answer:
            "Niftyz have developed two types of account - Basic (Free) and Pro (Premium) accounts. <br> <br> Premium Account includes: <br> <ul><li>One Time Fee depending on point in release schedule</li><li>NFT creation: <ul><li>Small fee to create airdrops of 0.00005 ETH</li></ul></li><li>Transaction fees: <ul><li>Transaction fee of 3% on sale - seller side</li></ul></li><li>Unlimited number of Pass Tokens (cannot be renamed after minting)</li><li>Unlimited collections</li><li>Unlimited number of links per collection</li><li>Access to the Partners and Ambassador program and earn from referrals (See partnerships for more information)</li><li>FREE Partnerships Certification Training Program</li><li>Premium Niftyz profile (Blue background)</li><li>Content Gate (for paid content)</li><li>Content Gate to a selected audience</li><li>Audience gate / select specific user rights for airdrops</li></ul> <br> The Basic account includes: <br> <ul> <li>FREE Account creation</li> <li>NFT creation :<ul><li>Small fee to create airdrops of 0.0001 ETH</li></ul></li><li>Transaction fees: <ul><li>Transaction fee of 5% on sale - seller side</li></ul> <li>Unlimited number of Pass Tokens (cannot be renamed after minting)</li><li>Unlimited number of links and documents per NFT</li><li>Content Gate for paid content</li> <li>Shareable QR code for profile page with Niftyz logo (white background)</li> <li>Exclusive perks - Public Discord channel, invites to public events and challenges, discounts / airdrops / merchandise from partners, Analytics</li><li>NFTs created will feature in Niftyz Marketplace and OpenSea</li></ul>",
        },
      ],
      isMobile: false,
    };
  },
  mounted() {
    const app = this;
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
};
</script>

<style></style>
