<template>
  <div class="container section-box">
    <div class="row">
      <div class="col-12 col-md-6 order-1 order-md-0 text-center">
        <img src="../../assets/image_1.0.png" alt="" />
      </div>
      <div
        :class="isMobile ? 'p-1 text-center' : 'pe-5'"
        class="col-12 col-md-6 order-0 order-md-1"
      >
        <h3>NFT templates for your new digital property</h3>
        <p>
          Choose from available templates designed for businesses or create your
          own. Add value to your end customers. Be part of a new business growth
          economy.
        </p>
      </div>
    </div>
    <div class="row">
      <div
        :class="isMobile ? 'p-1 text-center' : 'ps-5'"
        class="col-12 col-md-6 order-0 order-md-1"
      >
        <h3>Add unlockable content</h3>
        <p>
          Get more value out of your intellectual property. Put your business
          assets to work: your time, expertise, knowledge, documentation - pitch
          decks, proposals, audits, recommendations, deliverables, and more!
        </p>
      </div>
      <div class="col-12 col-md-6 order-0 order-md-1 text-center">
        <img src="../../assets/image_1.1.png" alt="" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 order-1 order-md-0 text-center">
        <img src="../../assets/image_1.2.png" alt="" />
      </div>
      <div
        :class="isMobile ? 'p-1 text-center' : 'pe-5'"
        class="col-12 col-md-6 order-0 order-md-1"
      >
        <h3>Mint, Share and Earn</h3>
        <p>
          New ideas bring new concepts. The core actions will be familiar to
          you: Set your own rules, targeting and access rights, quantities of
          NFTs, price, timings… Mint and earn from your digital property.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstSection",
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    const app = this;
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
};
</script>

<style></style>
