import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/mint/:referral",
    name: "Mint",
    // route level code-splitting
    // this generates a separate chunk (mint.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mint" */ "../views/Mint.vue"),
  },
  {
    path: "/physical/:physicalToken",
    name: "MintPhysical",
    // route level code-splitting
    // this generates a separate chunk (mint.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mint" */ "../views/Mint.vue"),
  },
  {
    path: "/list/:list",
    name: "MintList",
    // route level code-splitting
    // this generates a separate chunk (mint.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "mint" */ "../views/MintList.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (mint.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, savedPosition) {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    if (to.hash) {
      return {
        top: 0,
        el: to.hash,
        savedPosition,
      };
    }
  },
});

export default router;
