<template>
  <div class="perks">
    <div class="container">
      <div class="row">
        <div class="col-12 title-perks">
          <h2>Your NFT Experience Starts Here</h2>
          <p>Join and discover perks included in your Niftyz account</p>
        </div>
        <div class="col-12 col-md-4 box-perk">
          <img src="../../assets/events_icon_1.0.png" alt="" />
          <h3>Business Identity</h3>
          <p>
            Your unique QR code is linked <br> to your digital property NFTs. <br> Share &
            Earn.
          </p>
        </div>
        <div class="col-12 col-md-4 box-perk">
          <img src="../../assets/perks_icon_1.0.png" alt="" />
          <h3>Benefits & Perks</h3>
          <p>
            Access discounts & promotions <br> from selected partners in Niftyz.
          </p>
        </div>
        <div class="col-12 col-md-4 box-perk">
          <img src="../../assets/discord_icon_1.0.png" alt="" />
          <h3>Community </h3>
          <p>A unique community. <br> Network with like-minded businesses.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Perks",
};
</script>

<style></style>
