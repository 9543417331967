<template>
  <div class="offers mt-5">
    <h2 class="text-uppercase">BECOME ONE IN ONE THOUSAND</h2>
    <div class="plans">
      <!-- <div @click="offers1 = !offers1" :class="offers1 ? 'active' : null" class="plan free" style="background-color:white;">
            <div class="type-plan">
                <h4>Free {{offers1}}</h4>
            <div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deleniti consequuntur </p>
                <ul>
                    <li>point 1</li>
                    <li>point 2</li>
                    <li>point 3</li>
                </ul>
            </div>
            </div>
            <div class="style-btn">
                <button>Register</button>
            </div>
        </div> -->
      <!--  <carousel
        v-if="isMobile"
        class="w-100"
        :items-to-show="1"
        :wrap-around="false"
        :mouseDrag="true"
        :touchDrag="true"
      >
        <slide key="index">
          <div class="plan first-plan">
            <div class="top-descr"></div>
            <div class="d-flex flex-column justify-content-between h-100">
              <div class="type-plan">
                <h4>0.1 ETH</h4>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos
                    deleniti consequuntur
                  </p>
                  <ul>
                    <li>point 1</li>
                    <li>point 2</li>
                    <li>point 3</li>
                    <li>point 4</li>
                  </ul>
                </div>
              </div>
              <div class="style-btn">
                <button @click="showModal()">Buy</button>
              </div>
            </div>
          </div>
        </slide>
        <slide key="index">
          <div class="plan">
            <div class="top-descr">
              <p><strong>Private Sale 1</strong></p>
              <p>185/300 Accounts Available</p>
            </div>
            <div class="d-flex flex-column justify-content-between h-100">
              <div class="type-plan">
                <h4>0.5 ETH</h4>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos
                    deleniti consequuntur
                  </p>
                  <ul>
                    <li>point 1</li>
                    <li>point 2</li>
                    <li>point 3</li>
                    <li>point 4</li>
                  </ul>
                </div>
              </div>
              <div class="style-btn">
                <button disabled class="btn-disable">Coming Soon</button>
              </div>
            </div>
          </div>
        </slide>
        <slide key="index">
          <div class="plan">
            <div class="top-descr">
              <p><strong>Private Sale 2</strong></p>
              <p>500 Accounts Available</p>
            </div>
            <div class="d-flex flex-column justify-content-between h-100">
              <div class="type-plan">
                <h4>1.0 ETH</h4>
                <div>
                  <p>
                    This PRE-LAUNCH Premium account will give you access to the
                    following features:
                  </p>
                  <ul>
                    <li>Premium Niftyz Badge</li>
                    <li>Unique QR code for your Business</li>
                    <li>Unlimited number of Token Pass</li>
                    <li>Access to Partners Certification program</li>
                    <li>Niftyz perks</li>
                    <li>Zero minting costs option</li>
                    <li>And much more...</li>
                  </ul>
                </div>
              </div>
              <div class="style-btn">
                <button disabled class="btn-disable">Coming Soon</button>
              </div>
            </div>
          </div>
        </slide>
        <slide key="index">
          <div class="plan">
            <div class="top-descr">
              <p><strong>Private Sale 3</strong></p>
              <p>1000 Accounts Available</p>
            </div>
            <div class="d-flex flex-column justify-content-between h-100">
              <div class="type-plan">
                <h4>1.25 ETH</h4>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos
                    deleniti consequuntur
                  </p>
                  <ul>
                    <li>point 1</li>
                    <li>point 2</li>
                    <li>point 3</li>
                    <li>point 4</li>
                    <li>point 5</li>
                    <li>point 6</li>
                  </ul>
                </div>
              </div>
              <div class="style-btn">
                <button disabled class="btn-disable">Coming Soon</button>
              </div>
            </div>
          </div>
        </slide>
        <slide key="index">
          <div class="plan">
            <div class="top-descr">
              <p><strong>Private Sale </strong></p>
            </div>
            <div class="d-flex flex-column justify-content-between h-100">
              <div class="type-plan">
                <h4>1.5 ETH</h4>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos
                    deleniti consequuntur
                  </p>
                  <ul>
                    <li>point 1</li>
                    <li>point 2</li>
                    <li>point 3</li>
                    <li>point 4</li>
                    <li>point 5</li>
                    <li>point 6</li>
                  </ul>
                </div>
              </div>
              <div class="style-btn">
                <button disabled class="btn-disable">Coming Soon</button>
              </div>
            </div>
          </div>
        </slide>

        <template #addons="{ slidesCount }">
          <Navigation v-if="slidesCount > 1" />
          <Pagination />
        </template>
      </carousel> -->
      <Modal v-show="isModalVisible" @close="closeModal" />
      <div class="d-flex">
        <div
          :class="offers1 ? 'active' : null"
          class="plan first-plan position-relative"
        >
          <h3>Premium OGs Niftyz Badge</h3>
          <div class="top-descr"></div>
          <div class="d-flex flex-column justify-content-between h-100">
            <div class="type-plan">
              <h4>{{ price }} ETH</h4>
              <div>
                <p>
                  This PRE-LAUNCH Premium account will give you access to the
                  following features:
                </p>
                <ul>
                  <li>Premium OGs Niftyz Badge</li>
                  <li>Unique QR code for your Business</li>
                  <li>Unlimited number of Token Pass</li>
                  <li>Access to Partners Certification program</li>
                  <li>Niftyz perks</li>
                  <li>Zero minting costs option</li>
                  <li>And much more...</li>
                </ul>
              </div>
            </div>
            <div class="style-btn">
              <button id="mint-buy" @click="showModal()">Buy</button>
              <!-- <button
                v-if="!mintButton"
                @click="mintButton = !mintButton"
                class="button-guide ft-2 text-uppercase"
              >
                Mint
              </button>
              <div v-if="mintButton">
                <MintButton />
              </div> -->
            </div>
          </div>
        </div>
        <!-- <div :class="offers2 ? 'active' : null" class="plan position-relative">
          <h3>Premium Plans</h3>
          <div class="top-descr">
            <p><strong>Private Sale 1</strong></p>
            <p>185/300 Accounts Available</p>
          </div>
          <div class="d-flex flex-column justify-content-between h-100">
            <div class="type-plan">
              <h4>0.5 ETH</h4>
              <div>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos
                  deleniti consequuntur
                </p>
                <ul>
                  <li>point 1</li>
                  <li>point 2</li>
                  <li>point 3</li>
                  <li>point 4</li>
                </ul>
              </div>
            </div>
            <div class="style-btn">
              <button disabled class="btn-disable">Coming Soon</button>
            </div>
          </div>
        </div>
        <div :class="offers3 ? 'active' : null" class="plan">
          <div class="top-descr">
            <p><strong>Private Sale 2</strong></p>
            <p>500 Accounts Available</p>
          </div>
          <div class="d-flex flex-column justify-content-between h-100">
            <div class="type-plan">
              <h4>1.0 ETH</h4>
              <div>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos
                  deleniti consequuntur
                </p>
                <ul>
                  <li>point 1</li>
                  <li>point 2</li>
                  <li>point 3</li>
                  <li>point 4</li>
                  <li>point 5</li>
                </ul>
              </div>
            </div>
            <div class="style-btn">
              <button disabled class="btn-disable">Coming Soon</button>
            </div>
          </div>
        </div>
        <div :class="offers4 ? 'active' : null" class="plan">
          <div class="top-descr">
            <p><strong>Private Sale 3</strong></p>
            <p>1000 Accounts Available</p>
          </div>
          <div class="d-flex flex-column justify-content-between h-100">
            <div class="type-plan">
              <h4>1.25 ETH</h4>
              <div>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos
                  deleniti consequuntur
                </p>
                <ul>
                  <li>point 1</li>
                  <li>point 2</li>
                  <li>point 3</li>
                  <li>point 4</li>
                  <li>point 5</li>
                  <li>point 6</li>
                </ul>
              </div>
            </div>
            <div class="style-btn">
              <button disabled class="btn-disable">Coming Soon</button>
            </div>
          </div>
        </div>
        <div :class="offers5 ? 'active' : null" class="plan">
          <div class="top-descr">
            <p><strong>Private Sale </strong></p>
          </div>
          <div class="d-flex flex-column justify-content-between h-100">
            <div class="type-plan">
              <h4>1.5 ETH</h4>
              <div>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos
                  deleniti consequuntur
                </p>
                <ul>
                  <li>point 1</li>
                  <li>point 2</li>
                  <li>point 3</li>
                  <li>point 4</li>
                  <li>point 5</li>
                  <li>point 6</li>
                </ul>
              </div>
            </div>
            <div class="style-btn">
              <button disabled class="btn-disable">Coming Soon</button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
/* import MintButton from "@/components/MintButton.vue"; */
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import Modal from "@/components/Modal.vue";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import abi from "@/abi/abi.json";

export default {
  name: "Offers",
  data() {
    return {
      ABI: abi,
      contract: process.env.VUE_APP_CONTRACT_ADDRESS,
      network: process.env.VUE_APP_NETWORK,
      domain: process.env.VUE_APP_DOMAIN,
      api: process.env.VUE_APP_APIURL,
      infuraId: "de09a7825efa417686d0b08abe19679f",
      offers1: false,
      offers2: false,
      offers3: false,
      offers4: false,
      offers5: false,
      isMobile: false,
      isModalVisible: false,
      price: 0.2,
      networks: {
        ethereum: 1,
        rinkeby: 4,
        goerli: 5,
        polygon: 137,
        mumbai: 80001,
        ganache: 5777,
      },

      /* mintButton: false, */
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Modal,
    /* MintButton, */
  },
  methods: {
    async connect() {
      const app = this;
      let providerOptions = {};
      if (app.infuraId !== undefined) {
        console.log("Using infuraid:", app.infuraId);
        providerOptions = {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId: app.infuraId,
            },
          },
        };
      }
      // Instantiating Web3Modal
      const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions: providerOptions,
      });
      const provider = await web3Modal.connect();
      app.web3 = await new Web3(provider);
      // Checking if networkId matches
      const netId = await app.web3.eth.net.getId();
      if (parseInt(netId) !== app.networks[app.network]) {
        alert("Switch to " + app.network + " network!");
      } else {
        const nftContract = new app.web3.eth.Contract(app.ABI, app.contract);
        let price = await nftContract.methods.public_price().call();
        app.price = await app.web3.utils.fromWei(price, "ether");
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  async mounted() {
    const app = this;
    await app.connect();

    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
};
</script>

<style>
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}
</style>
