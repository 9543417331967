<template>
  <div class="more">
      <img src="../../assets/partners.svg" alt="">
      <div class="text-more">
          <h3>Become a Niftyz <span>Partner</span></h3>
          <p>Join our Partner Program and take advantage of our amazing perks, community, certification and more!</p>
          <div class="btn-more">
              <a href="#/about">Find out more</a>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name:"More"

}
</script>

<style>

</style>