<template>
  <div class="jumbotron">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-6 order-1 order-md-0">
          <h3 class="mb-4">Turn Ideas Into Revenue</h3>
          <h3>
            Introducing The NFT <br />
            <strong>Platform for Business</strong>
          </h3>
          <p>
            Niftyz creates new digital property NFTs from assets you already
            own: documents, deal flows, events, communities & even your
            knowledge. <br> Get started now.
          </p>
        </div>
        <div class="col-12 col-md-6 order-0 order-md-1 text-center animation">
          <Animation />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Animation from "@/components/Homepage/Animation.vue";

export default {
  name: "Jumbotron",
  components: {
    Animation,
  },
};
</script>

<style></style>
