<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <button type="button" class="btn-chiuso" @click="close">x</button>
      </header>

      <section class="modal-body">
        <slot name="body">
          <!-- <button
            v-if="!mintButton"
            @click="mintButton = !mintButton"
            class="button-guide ft-2 text-uppercase"
          >
            Minto
          </button> -->
          <div v-if="!mintButton && !mintPhysical">
            <MintButton />
          </div>
          <div v-if="!mintButton && mintPhysical">
            <MintButtonPhysical />
          </div>
        </slot>
      </section>

      <!-- <footer class="modal-footer">
        <slot name="footer"> This is the default footer! </slot>
        <button type="button" class="btn-green" @click="close">
          Close Modal
        </button>
      </footer> -->
    </div>
  </div>
</template>

<script>
import MintButton from "@/components/MintButton.vue";
import MintButtonPhysical from "./MintButtonPhysical.vue";
export default {
  name: "Modal",
  data() {
    return {
      mintButton: false,
      mintPhysical: false,
    };
  },
  components: {
    MintButton,
    MintButtonPhysical
},
  methods: {
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    if (this.$route.params["physicalToken"] !== undefined)
      this.mintPhysical = true;

  }
};
</script>

<style lang="scss">
.modal-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #010122b3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 50vw;
  height: 50vh;
  position: relative;
  z-index: 999;
  background: #020230;
  border-radius: 10px;
  box-shadow: 2px 2px 20px 1px rgba(0, 0, 0, 0.686);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  h5{
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
  }
}
.mint-wrapper{
  h3{
    font-size: 1.6rem!important;
    margin-bottom: 2rem;
  }
  h4{
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }
  h6{
    margin-top: 1.5rem;
  }
}
.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  height: 100%;
  padding: 20px 10px;
}

.btn-chiuso {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 0px 10px;
  background: transparent;
  cursor: pointer;
  font-weight: bold;
  color: #ffffff;
  &:hover{
    color: white!important;;
  }
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}
.button-guide {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  color: white;
  border: none;
  padding: 0.4rem;
  background: transparent linear-gradient(208deg, #6600cc 0%, #0099ff 100%) 0%
    0% no-repeat padding-box;
  font-size: 1rem;
  font-weight: 300;
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  font-size: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.button-guide:hover {
  transition: all 0.45s;
  color: white;
  @keyframes bob {
    0% {
      background: transparent linear-gradient(208deg, #6600cc 0%, #0099ff 90%)
        0% 0% no-repeat padding-box;
    }
    20% {
      background: transparent linear-gradient(208deg, #6600cc 5%, #0099ff 100%)
        0% 0% no-repeat padding-box;
    }
    40% {
      background: transparent linear-gradient(208deg, #6600cc 10%, #0099ff 110%)
        0% 0% no-repeat padding-box;
    }
    60% {
      background: transparent linear-gradient(208deg, #6600cc 15%, #0099ff 120%)
        0% 0% no-repeat padding-box;
    }
    80% {
      background: transparent linear-gradient(208deg, #6600cc 20%, #0099ff 130%)
        0% 0% no-repeat padding-box;
    }
    100% {
      background: transparent linear-gradient(208deg, #6600cc 25%, #0099ff 140%)
        0% 0% no-repeat padding-box;
    }
  }
  animation: bob 0.5s ease-in-out alternate;
  background: transparent linear-gradient(208deg, #6600cc 30%, #0099ff 150%) 0%
    0% no-repeat padding-box;
}
</style>
