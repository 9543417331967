<template>
  <div class="home">
    <Header />
    <Jumbotron />
    <div class="bk-img bk-first"></div>
    <div class="shadow"></div>
    <div class="bk-img bk-second"></div>
    <FirstSection />
    <Perks />
    <div class="second-shadow"></div>
    <div id="mint">
      <Offers />
    </div>
    <Patners />
    <div class="bk-img bk-four"></div>
    <Faqs />
    <div class="bk-green"></div>
    <More />
    <Footer />

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Jumbotron from '@/components/Homepage/Jumbotron.vue'
import FirstSection from '@/components/Homepage/FirstSection.vue'
import Perks from '@/components/Homepage/Perks.vue'
import Offers from '@/components/Homepage/Offers.vue'
import Patners from '@/components/Homepage/Patners.vue'
import Faqs from '@/components/Homepage/Faqs.vue'
import More from '@/components/Homepage/More.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Jumbotron,
    FirstSection,
    Perks,
    Offers,
    Patners,
    Faqs,
    More,
    Footer
  }
}
</script>
