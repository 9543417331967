import { createApp } from 'vue'
/* import { createPinia, PiniaVuePlugin } from "pinia"; */
import App from './App.vue'
import router from './router'







import "@/themes/style.scss"
import "@/themes/resp.scss"

/* Vue.use(PiniaVuePlugin);
const pinia = createPinia(); */

const app = createApp(App)
app.use(router).mount('#app')
