<template>
  <div class="container-animation">
      <div id="test">
          <img class="csv" src="../../assets/animation/csv.svg" alt="" />
          <img class="jpeg" src="../../assets/animation/jpeg.svg" alt="" />
          <img class="red-doc" src="../../assets/animation/red-doc.svg" alt="" />
          <img class="mov" src="../../assets/animation/mov.svg" alt="" />
          <img class="doc" src="../../assets/animation/doc.svg" alt="" />
      </div>
      <div class="d-flex justify-content-center align-items-center h-100">
          <img id="qcode" class="d-none" src="../../assets/animation/Group.png" alt="">
      </div>
  </div>
</template>

<script>
export default {
    name:"Animation",
    methods:{
        yourMethod() {
            document.getElementById("test").classList.add('d-none');
            document.getElementById("qcode").classList.remove('d-none');
            document.getElementById("qcode").classList.add('d-block');
        }
    },
    mounted(){
        setTimeout(()=>{
        this.yourMethod()
        },3000);
    }
}
</script>

<style></style>
